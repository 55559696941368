import Naja from 'naja';
import CountdownTimer from '../classes/countdown.js'; 

function initCountdownTimers(paramContainer) {
    const container = paramContainer || document.body;

    container.querySelectorAll('.product-link').forEach((linkElement) => {
        linkElement.addEventListener('click', (event) => {
            event.preventDefault();
            const countdownElement = linkElement.nextElementSibling;
            if (countdownElement && countdownElement.dataset.auiCountdown) {
                CountdownTimer.initCountdown(countdownElement);
            } else {
                console.error('Countdown element not found.');
            }
        });
    });
}

document.addEventListener('DOMContentLoaded', () => {
    initCountdownTimers(document.body);

    const naja = new Naja();
    naja.initialize();
    naja.addEventListener('complete', () => {
        initCountdownTimers(document.body);
    });
});
