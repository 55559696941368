const header = document.querySelector('[data-sticky-header]');

import naja from 'naja';
import StickyHeader from '../classes/sticky-header';

export default function initStickyHeader(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-sticky-header]')?.forEach((stickyHeaderElement) => {
    StickyHeader.init(stickyHeaderElement);
  });
}

naja.addEventListener('init', () => {
  initStickyHeader(document.body);
});
