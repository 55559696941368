import naja from 'naja';
import PasswordReveal from '../classes/password-reveal';

export default function initPasswordReveal(paramContainer) {
  const container = paramContainer || document.body;

  container.querySelectorAll('[data-aui-password-reveal]')?.forEach((mapElement) => {
    PasswordReveal.init(mapElement);
  });
}

naja.addEventListener('init', () => {
    initPasswordReveal(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    initPasswordReveal(event.detail.snippet);
});
