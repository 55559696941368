export default class PasswordReveal {
    static init(passwordRevealElement) {
      console.log("Nšco")
       
        const targetId = passwordRevealElement.dataset?.auiPasswordReveal;
        const targetInput = document.querySelector(targetId);
            console.log(targetId)
            console.log(targetInput)

  
        if (targetInput) {
            passwordRevealElement.addEventListener('click', () => {
                console.log("dalsi krok")
            PasswordReveal.toggleVisibility(targetInput);
          });
        }
    };
  
    static toggleVisibility(input) {
        console.log(input.type)
        input.type = input.type === 'password' ? 'text' : 'password';
    };
  }
  