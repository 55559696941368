export default class StickyHeader {
  static init(stickyHeaderElement) {
    const stickyHeaderClass = stickyHeaderElement.dataset?.auiStickyHeaderClass ?? 'is-sticky';
    const stickyHeaderOffset = stickyHeaderElement.offsetTop;

    const onScroll = () => {
      if (window.scrollY > stickyHeaderOffset) {
        stickyHeaderElement.classList.add(stickyHeaderClass);
      } else {
        stickyHeaderElement.classList.remove(stickyHeaderClass);
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
  }
}
