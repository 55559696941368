import naja from 'naja';

import './inits/viewport';
import './inits/modal';
import './inits/tabs';
import './inits/tooltip';
import './inits/toast';
import './inits/grid';
import './inits/gallery';
import './inits/scroll';
import './inits/counter';
import './inits/map';
import './inits/icon';
import './inits/announcement';
import './inits/loader-overlay';
import './inits/cookies-policy';
import './inits/show-slide';
import './inits/password-reveal';
import './inits/slider';
import './inits/spinbox';
import './inits/sticky-header';
import './inits/video';
import './inits/header';
import './inits/form';
import './inits/countdown';

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));