export default class CountdownTimer {
    static initCountdown(element) {
        const endTime = new Date(element.dataset.auiCountdown);
        if (isNaN(endTime)) {
            console.error('Invalid date format for countdown:', element.dataset.auiCountdown);
            return;
        }
        const timer = new CountdownTimer(endTime, element);
        timer.updateTimer();
        timer.intervalId = setInterval(() => timer.updateTimer(), 1000); 
    }
    
    constructor(endTime, element) {
        this.endTime = endTime;
        this.element = element;
    }

    updateTimer() {
        const currentTime = new Date();
        const remainingTime = this.endTime - currentTime;

        if (remainingTime <= 0) {
            this.element.textContent = "Konec!";
            clearInterval(this.intervalId);
            return;
        }

        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));

        const countdownString = `${days}d ${hours}h ${minutes}m ${seconds}s`;
        this.element.textContent = countdownString;
    }
}
