import Ajax from 'adaptive-ui/src/js/utils/ajax';
import Translator from 'adaptive-ui/src/js/utils/translator';
import ModalSide from "../components/modal-side";

export default class AddToCart {

    static ajaxSubmit(event) {
        event.preventDefault();
        event.stopImmediatePropagation();

        Ajax.submitForm(event.target)
            .then(AddToCart.onSuccess)
            .catch(AddToCart.onError);
    }

    static onSuccess(response) {
        ModalSide.showModalSide('cart');
    }

    static onError(error) {
        if (error.status !== 0) {
            new window.aui.modal({
                type: 'sm',
                title: Translator.translate('Chyba'),
                body: Translator.translate('Nepodařilo se vložit produkt do košíku, prosím kontaktujte nás.'),
                buttons: {
                    cancel: {
                        text: Translator.translate('Zavřít'),
                        isVisible: true,
                    }
                }
            });
        }
    }

    static init(container) {
        container.querySelectorAll('.js-product-add-to-cart-form').forEach(form => {
            form.addEventListener('submit', AddToCart.ajaxSubmit);
        });
    }
}

naja.addEventListener('init', () => {
    AddToCart.init(document.body);
});

naja.snippetHandler.addEventListener('afterUpdate', (event) => {
    AddToCart.init(event.detail.snippet);
});
